import React from 'react';
import {Link, graphql} from 'gatsby';
import moment from 'moment';
import Img from 'gatsby-image';
import {Icon} from 'rsuite';

import Bio from '../components/bio';
import Layout from '../components/layout';
import SEO from '../components/seo';

import './index.scss';

const BlogPostTemplate = ({data, pageContext, location}) => {
  const post = data.markdownRemark;
  const siteTitle = data.site.siteMetadata.title;
  const {previous, next} = pageContext;

  return (
    <div key={post.frontmatter.title}>
      <Layout location={location} title={siteTitle}>
        <SEO title={post.frontmatter.title} />
        <article>
          <header className="article-title">
            <h1>{post.frontmatter.title}</h1>
            <p>{moment(post.frontmatter.date).format('YYYY/MM/DD')}</p>
          </header>
          <div className="thumbnail">
            <Img
              sizes={post.frontmatter.featuredImage.childImageSharp.sizes}
              fluid={{sizes: ''}}
            />
          </div>
          <section dangerouslySetInnerHTML={{__html: post.html}} />
          <hr />
          <nav>
            <ul
              className="paging"
              style={{
                display: `flex`,
                flexWrap: `wrap`,
                justifyContent: `space-between`,
                listStyle: `none`,
                padding: 0,
              }}
            >
              <li className="pager">
                {previous && (
                  <Link to={previous.fields.slug} rel="prev">
                    ← {previous.frontmatter.title}
                  </Link>
                )}
              </li>
              <li>
                {next && (
                  <Link to={next.fields.slug} rel="next">
                    {next.frontmatter.title} →
                  </Link>
                )}
              </li>
              <div className="link-home">
                <Link to="/">
                  <Icon className="fill-color" icon="home" size="lg" />
                  Back To Top
                </Link>
              </div>
            </ul>
          </nav>

          <footer className="detail-footer">
            <Bio />
          </footer>
        </article>
      </Layout>
    </div>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: {slug: {eq: $slug}}) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        featuredImage {
          childImageSharp {
            sizes(maxWidth: 400) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    }
  }
`;
